import React, { Component } from 'react'
import cn from 'clsx'
import ExternalLink from 'react-icons/lib/fa/external-link'
import FullscreenImage from '@components/FullscreenImage'
import MusicBgImage from '@images/music-bg.jpg'
import Layout from '@layout'

const MusicLink = ({ href, name }) => (
  <a href={href} target="_blank" className="music-link">
    {name} <ExternalLink />
  </a>
)

class MusicPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
    this.onImageLoaded = this.onImageLoaded.bind(this)
  }

  onImageLoaded() {
    this.setState({ loaded: true })
  }

  render() {
    const pageClass = cn('musicpage', { loaded: this.state.loaded })
    return (
      <Layout>
      <div className={pageClass}>
        <FullscreenImage image={MusicBgImage} loaded={this.onImageLoaded} />
        <div className="container">
          <div className="music-container">
            <MusicLink href="http://tidal.com/artist/5006325" name="Tidal" />
            <MusicLink
              href="https://itunes.apple.com/no/artist/the-human-nature/id668558264?l=nb"
              name="iTunes"
            />
            <MusicLink
              href="https://open.spotify.com/artist/00m9Z2Oqpwc6dgneEsKklB"
              name="Spotify"
            />

            <div className="spotify">
              <iframe
                src="https://open.spotify.com/embed?uri=spotify:artist:00m9Z2Oqpwc6dgneEsKklB"
                width="300"
                height="380"
                frameBorder="0"
                allowTransparency="true"
              />
            </div>
          </div>
        </div>
      </div>
      </Layout>
    )
  }
}

export default MusicPage
